import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Iframe from 'react-iframe';

import PageLayout from '../layouts/Page';

export default function IframeTester() {
  const [urlToTest, setUrlToTest] = useState(null);
  const [iframeWidth, setIframeWidth] = useState(null);
  const [iframeHeight, setIframeHeight] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    setIframeWidth(formJson.iframeWidth);
    setIframeHeight(formJson.iframeHeight);
    setUrlToTest(formJson.urlToTest);
  };

  return (
    <PageLayout>
      <Helmet>
        <title>Iframe tester</title>
      </Helmet>
      <div className=" py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="font-title mb-4 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Iframe tester
          </h1>
          <div className="max-w-xs mx-auto">
            <form method="post" onSubmit={handleSubmit}>
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="urlToTest"
                >
                  URL à tester
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-green-brand"
                  id="urlToTest"
                  name="urlToTest"
                  type="url"
                  pattern="https?://.+"
                  required
                  placeholder="https://example.com"
                />
              </div>
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="iframeWidth"
                >
                  Largeur de l'iframe
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-green-brand"
                  id="iframeWidth"
                  name="iframeWidth"
                  type="number"
                  required
                  placeholder="Largeur de l'iframe"
                  defaultValue={1024}
                />
              </div>
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="iframeHeight"
                >
                  Hauteur de l'iframe
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-green-brand"
                  id="iframeHeight"
                  name="iframeHeight"
                  type="number"
                  required
                  placeholder="Hauteur de l'iframe"
                  defaultValue={800}
                />
              </div>

              <button
                className="w-full whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-brand hover:bg-green-brandHover"
                type="submit"
              >
                Lancer l'iframe
              </button>
            </form>
          </div>
        </div>
        {urlToTest && (
          <div className="mt-8 flex justify-center	">
            <Iframe
              className="border-solid border border-green-brand"
              url={urlToTest}
              width={iframeWidth}
              height={iframeHeight}
            />
          </div>
        )}
      </div>
    </PageLayout>
  );
}
